const vectorDraws = {
  mirror: (ctx: CanvasRenderingContext2D, coordsX: number = 0, coordsY: number = 0) => {
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.font = "15px ''";
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.font = "   15px ''";
    ctx.save();
    ctx.fillStyle = "white";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.arc(coordsX + 32, coordsY + 32, 27, 0, 6.283185307179586, false);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.font = "   15px ''";
    ctx.translate(4, 4);
    ctx.beginPath();
    ctx.moveTo(coordsX + 0, coordsY + 0);
    ctx.lineTo(coordsX + 55.9, coordsY + 0);
    ctx.bezierCurveTo(coordsX + 55.9, coordsY + 0, coordsX + 55.9, coordsY + 0, coordsX + 55.9, coordsY + 0);
    ctx.lineTo(coordsX + 55.9, coordsY + 55.9);
    ctx.bezierCurveTo(coordsX + 55.9, coordsY + 55.9, coordsX + 55.9, coordsY + 55.9, coordsX + 55.9, coordsY + 55.9);
    ctx.lineTo(coordsX + 0, coordsY + 55.9);
    ctx.bezierCurveTo(coordsX + 0, coordsY + 55.9, coordsX + 0, coordsY + 55.9, coordsX + 0, coordsY + 55.9);
    ctx.lineTo(coordsX + 0, coordsY + 0);
    ctx.bezierCurveTo(coordsX + 0, coordsY + 0, coordsX + 0, coordsY + 0, coordsX + 0, coordsY + 0);
    ctx.closePath();
    ctx.translate(- 4, - 4);
    ctx.clip();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 9.29999, coordsY + 40.0002);
    ctx.bezierCurveTo(coordsX + 8.79999, coordsY + 40.0002, coordsX + 8.39999, coordsY + 39.7002, coordsX + 8.29999, coordsY + 39.2002);
    ctx.bezierCurveTo(coordsX + 8.19999, coordsY + 38.7002, coordsX + 8.49999, coordsY + 38.1002, coordsX + 9.09999, coordsY + 38.0002);
    ctx.lineTo(coordsX + 26.4, coordsY + 34.0002);
    ctx.lineTo(coordsX + 26.9, coordsY + 31.4002);
    ctx.lineTo(coordsX + 18.4, coordsY + 20.0002);
    ctx.bezierCurveTo(coordsX + 18.1, coordsY + 19.6002, coordsX + 18.2, coordsY + 18.9002, coordsX + 18.6, coordsY + 18.6002);
    ctx.bezierCurveTo(coordsX + 19, coordsY + 18.3002, coordsX + 19.7, coordsY + 18.4002, coordsX + 20, coordsY + 18.8002);
    ctx.lineTo(coordsX + 28.8, coordsY + 30.6002);
    ctx.bezierCurveTo(coordsX + 29, coordsY + 30.8002, coordsX + 29, coordsY + 31.1002, coordsX + 29, coordsY + 31.4002);
    ctx.lineTo(coordsX + 28.2, coordsY + 35.1002);
    ctx.bezierCurveTo(coordsX + 28.1, coordsY + 35.5002, coordsX + 27.8, coordsY + 35.8002, coordsX + 27.4, coordsY + 35.9002);
    ctx.lineTo(coordsX + 9.59999, coordsY + 40.0002);
    ctx.bezierCurveTo(coordsX + 9.49999, coordsY + 40.0002, coordsX + 9.39999, coordsY + 40.0002, coordsX + 9.29999, coordsY + 40.0002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 35.4, coordsY + 35.8);
    ctx.bezierCurveTo(coordsX + 35.2, coordsY + 35.8, coordsX + 35.1, coordsY + 35.8, coordsX + 34.9, coordsY + 35.7);
    ctx.bezierCurveTo(coordsX + 34.6, coordsY + 35.6, coordsX + 34.4, coordsY + 35.3, coordsX + 34.4, coordsY + 34.9);
    ctx.lineTo(coordsX + 33.6, coordsY + 29.3);
    ctx.bezierCurveTo(coordsX + 33.6, coordsY + 29.1, coordsX + 33.6, coordsY + 28.9, coordsX + 33.7, coordsY + 28.8);
    ctx.lineTo(coordsX + 38, coordsY + 18.4);
    ctx.bezierCurveTo(coordsX + 38.2, coordsY + 17.9, coordsX + 38.8, coordsY + 17.6, coordsX + 39.3, coordsY + 17.9);
    ctx.bezierCurveTo(coordsX + 39.8, coordsY + 18.1, coordsX + 40.1, coordsY + 18.7, coordsX + 39.8, coordsY + 19.2);
    ctx.lineTo(coordsX + 35.6, coordsY + 29.4);
    ctx.lineTo(coordsX + 36.1, coordsY + 33.3);
    ctx.lineTo(coordsX + 44.8, coordsY + 28.8);
    ctx.bezierCurveTo(coordsX + 45.3, coordsY + 28.5, coordsX + 45.9, coordsY + 28.7, coordsX + 46.1, coordsY + 29.2);
    ctx.bezierCurveTo(coordsX + 46.4, coordsY + 29.7, coordsX + 46.2, coordsY + 30.3, coordsX + 45.7, coordsY + 30.5);
    ctx.lineTo(coordsX + 35.7, coordsY + 35.6);
    ctx.bezierCurveTo(coordsX + 35.7, coordsY + 35.8, coordsX + 35.5, coordsY + 35.8, coordsX + 35.4, coordsY + 35.8);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 42.8, coordsY + 43.1);
    ctx.bezierCurveTo(coordsX + 42.5, coordsY + 43.1, coordsX + 42.3, coordsY + 43, coordsX + 42.1, coordsY + 42.8);
    ctx.bezierCurveTo(coordsX + 42, coordsY + 42.7, coordsX + 41.9, coordsY + 42.6, coordsX + 35.9, coordsY + 34.9);
    ctx.bezierCurveTo(coordsX + 35.7, coordsY + 34.6, coordsX + 35.6, coordsY + 34.2, coordsX + 35.8, coordsY + 33.9);
    ctx.lineTo(coordsX + 39, coordsY + 26.5);
    ctx.bezierCurveTo(coordsX + 39.2, coordsY + 26, coordsX + 39.8, coordsY + 25.8, coordsX + 40.3, coordsY + 26);
    ctx.bezierCurveTo(coordsX + 40.8, coordsY + 26.2, coordsX + 41, coordsY + 26.8, coordsX + 40.8, coordsY + 27.3);
    ctx.lineTo(coordsX + 37.8, coordsY + 34.2);
    ctx.bezierCurveTo(coordsX + 40.1, coordsY + 37.1, coordsX + 43.3, coordsY + 41.2, coordsX + 43.5, coordsY + 41.5);
    ctx.bezierCurveTo(coordsX + 43.9, coordsY + 41.9, coordsX + 43.8, coordsY + 42.5, coordsX + 43.4, coordsY + 42.9);
    ctx.bezierCurveTo(coordsX + 43.3, coordsY + 43, coordsX + 43.1, coordsY + 43.1, coordsX + 42.8, coordsY + 43.1);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 33.6, coordsY + 46.2998);
    ctx.bezierCurveTo(coordsX + 33.3, coordsY + 46.2998, coordsX + 33, coordsY + 46.1998, coordsX + 32.8, coordsY + 45.8998);
    ctx.bezierCurveTo(coordsX + 32.6, coordsY + 45.6998, coordsX + 32.4, coordsY + 45.3998, coordsX + 31.3, coordsY + 36.6998);
    ctx.lineTo(coordsX + 26, coordsY + 43.1998);
    ctx.bezierCurveTo(coordsX + 25.7, coordsY + 43.5998, coordsX + 25, coordsY + 43.6998, coordsX + 24.6, coordsY + 43.3998);
    ctx.bezierCurveTo(coordsX + 24.2, coordsY + 43.0998, coordsX + 24.1, coordsY + 42.3998, coordsX + 24.4, coordsY + 41.9998);
    ctx.lineTo(coordsX + 31.1, coordsY + 33.6998);
    ctx.bezierCurveTo(coordsX + 31.4, coordsY + 33.3998, coordsX + 31.8, coordsY + 33.2998, coordsX + 32.1, coordsY + 33.3998);
    ctx.bezierCurveTo(coordsX + 32.5, coordsY + 33.4998, coordsX + 32.8, coordsY + 33.7998, coordsX + 32.8, coordsY + 34.1998);
    ctx.bezierCurveTo(coordsX + 33.4, coordsY + 38.8998, coordsX + 34.2, coordsY + 44.1998, coordsX + 34.4, coordsY + 44.8998);
    ctx.bezierCurveTo(coordsX + 34.6, coordsY + 45.2998, coordsX + 34.5, coordsY + 45.7998, coordsX + 34.1, coordsY + 46.0998);
    ctx.bezierCurveTo(coordsX + 34, coordsY + 46.1998, coordsX + 33.8, coordsY + 46.2998, coordsX + 33.6, coordsY + 46.2998);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 18.3, coordsY + 38.0001);
    ctx.bezierCurveTo(coordsX + 18.2, coordsY + 38.0001, coordsX + 18.1, coordsY + 38.0001, coordsX + 17.9, coordsY + 37.9001);
    ctx.bezierCurveTo(coordsX + 17.4, coordsY + 37.7001, coordsX + 17.1, coordsY + 37.1001, coordsX + 17.3, coordsY + 36.6001);
    ctx.bezierCurveTo(coordsX + 17.6, coordsY + 35.9001, coordsX + 21.9, coordsY + 27.9001, coordsX + 23.2, coordsY + 25.5001);
    ctx.bezierCurveTo(coordsX + 23.4, coordsY + 25.1001, coordsX + 23.9, coordsY + 24.9001, coordsX + 24.4, coordsY + 25.0001);
    ctx.lineTo(coordsX + 34.9, coordsY + 28.3001);
    ctx.bezierCurveTo(coordsX + 35.4, coordsY + 28.5001, coordsX + 35.7, coordsY + 29.0001, coordsX + 35.6, coordsY + 29.6001);
    ctx.bezierCurveTo(coordsX + 35.4, coordsY + 30.1001, coordsX + 34.9, coordsY + 30.4001, coordsX + 34.3, coordsY + 30.3001);
    ctx.lineTo(coordsX + 24.6, coordsY + 27.3001);
    ctx.bezierCurveTo(coordsX + 22.5, coordsY + 31.2001, coordsX + 19.5, coordsY + 36.9001, coordsX + 19.2, coordsY + 37.5001);
    ctx.bezierCurveTo(coordsX + 19, coordsY + 37.7001, coordsX + 18.7, coordsY + 38.0001, coordsX + 18.3, coordsY + 38.0001);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 18.7, coordsY + 41.4001);
    ctx.bezierCurveTo(coordsX + 18.2, coordsY + 41.4001, coordsX + 17.8, coordsY + 41.1001, coordsX + 17.7, coordsY + 40.6001);
    ctx.bezierCurveTo(coordsX + 17.6, coordsY + 40.1001, coordsX + 18, coordsY + 39.5001, coordsX + 18.5, coordsY + 39.4001);
    ctx.lineTo(coordsX + 27, coordsY + 38.0001);
    ctx.lineTo(coordsX + 26.3, coordsY + 35.2001);
    ctx.bezierCurveTo(coordsX + 26.2, coordsY + 34.7001, coordsX + 26.5, coordsY + 34.1001, coordsX + 27, coordsY + 34.0001);
    ctx.bezierCurveTo(coordsX + 27.5, coordsY + 33.9001, coordsX + 28.1, coordsY + 34.2001, coordsX + 28.2, coordsY + 34.7001);
    ctx.lineTo(coordsX + 29.2, coordsY + 38.6001);
    ctx.bezierCurveTo(coordsX + 29.3, coordsY + 38.9001, coordsX + 29.2, coordsY + 39.2001, coordsX + 29.1, coordsY + 39.4001);
    ctx.bezierCurveTo(coordsX + 28.9, coordsY + 39.6001, coordsX + 28.7, coordsY + 39.8001, coordsX + 28.4, coordsY + 39.8001);
    ctx.lineTo(coordsX + 18.8, coordsY + 41.4001);
    ctx.bezierCurveTo(coordsX + 18.8, coordsY + 41.4001, coordsX + 18.7, coordsY + 41.4001, coordsX + 18.7, coordsY + 41.4001);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 37.2, coordsY + 41.9002);
    ctx.bezierCurveTo(coordsX + 36.9, coordsY + 41.9002, coordsX + 36.6, coordsY + 41.8002, coordsX + 36.4, coordsY + 41.5002);
    ctx.lineTo(coordsX + 31.1, coordsY + 34.8002);
    ctx.bezierCurveTo(coordsX + 30.8, coordsY + 34.5002, coordsX + 30.8, coordsY + 34.0002, coordsX + 31, coordsY + 33.7002);
    ctx.bezierCurveTo(coordsX + 31.2, coordsY + 33.3002, coordsX + 31.6, coordsY + 33.2002, coordsX + 32, coordsY + 33.2002);
    ctx.lineTo(coordsX + 35.5, coordsY + 33.8002);
    ctx.bezierCurveTo(coordsX + 36, coordsY + 33.9002, coordsX + 36.4, coordsY + 34.4002, coordsX + 36.3, coordsY + 34.9002);
    ctx.bezierCurveTo(coordsX + 36.2, coordsY + 35.4002, coordsX + 35.7, coordsY + 35.8002, coordsX + 35.2, coordsY + 35.7002);
    ctx.lineTo(coordsX + 34.3, coordsY + 35.6002);
    ctx.lineTo(coordsX + 38, coordsY + 40.3002);
    ctx.bezierCurveTo(coordsX + 38.3, coordsY + 40.7002, coordsX + 38.3, coordsY + 41.4002, coordsX + 37.8, coordsY + 41.7002);
    ctx.bezierCurveTo(coordsX + 37.6, coordsY + 41.8002, coordsX + 37.4, coordsY + 41.9002, coordsX + 37.2, coordsY + 41.9002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 43.1, coordsY + 50.2001);
    ctx.lineTo(coordsX + 11.3, coordsY + 50.2001);
    ctx.bezierCurveTo(coordsX + 11, coordsY + 50.2001, coordsX + 10.7, coordsY + 50.1001, coordsX + 10.5, coordsY + 49.8001);
    ctx.bezierCurveTo(coordsX + 6.3, coordsY + 44.8001, coordsX + 4, coordsY + 38.5001, coordsX + 4, coordsY + 31.9001);
    ctx.bezierCurveTo(coordsX + 4, coordsY + 29.1001, coordsX + 4.4, coordsY + 26.4001, coordsX + 5.2, coordsY + 23.8001);
    ctx.bezierCurveTo(coordsX + 5.3, coordsY + 23.6001, coordsX + 5.4, coordsY + 23.5001, coordsX + 5.5, coordsY + 23.3001);
    ctx.lineTo(coordsX + 12.3, coordsY + 17.3001);
    ctx.bezierCurveTo(coordsX + 15, coordsY + 14.9001, coordsX + 18.5, coordsY + 13.6001, coordsX + 22.1, coordsY + 13.6001);
    ctx.lineTo(coordsX + 43.2, coordsY + 13.6001);
    ctx.bezierCurveTo(coordsX + 48.5, coordsY + 13.6001, coordsX + 52.9, coordsY + 17.9001, coordsX + 52.9, coordsY + 23.2001);
    ctx.lineTo(coordsX + 52.9, coordsY + 40.4001);
    ctx.bezierCurveTo(coordsX + 52.8, coordsY + 45.9001, coordsX + 48.5, coordsY + 50.2001, coordsX + 43.1, coordsY + 50.2001);
    ctx.closePath();
    ctx.moveTo(coordsX + 11.7, coordsY + 48.2001);
    ctx.lineTo(coordsX + 43.1, coordsY + 48.2001);
    ctx.bezierCurveTo(coordsX + 47.3, coordsY + 48.2001, coordsX + 50.8, coordsY + 44.8001, coordsX + 50.8, coordsY + 40.6001);
    ctx.lineTo(coordsX + 50.8, coordsY + 23.3001);
    ctx.bezierCurveTo(coordsX + 50.8, coordsY + 19.1001, coordsX + 47.4, coordsY + 15.7001, coordsX + 43.1, coordsY + 15.7001);
    ctx.lineTo(coordsX + 22.1, coordsY + 15.7001);
    ctx.bezierCurveTo(coordsX + 19, coordsY + 15.7001, coordsX + 16, coordsY + 16.8001, coordsX + 13.7, coordsY + 18.9001);
    ctx.lineTo(coordsX + 7, coordsY + 24.7001);
    ctx.bezierCurveTo(coordsX + 6.3, coordsY + 27.1001, coordsX + 6, coordsY + 29.5001, coordsX + 6, coordsY + 32.0001);
    ctx.bezierCurveTo(coordsX + 6, coordsY + 37.8001, coordsX + 8, coordsY + 43.6001, coordsX + 11.7, coordsY + 48.2001);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 41.6, coordsY + 46.2998);
    ctx.lineTo(coordsX + 10.8, coordsY + 46.2998);
    ctx.bezierCurveTo(coordsX + 9.8, coordsY + 46.2998, coordsX + 8.9, coordsY + 46.1998, coordsX + 8, coordsY + 45.8998);
    ctx.bezierCurveTo(coordsX + 7.7, coordsY + 45.7998, coordsX + 7.5, coordsY + 45.6998, coordsX + 7.4, coordsY + 45.3998);
    ctx.bezierCurveTo(coordsX + 5.3, coordsY + 41.4998, coordsX + 4.1, coordsY + 37.0998, coordsX + 4, coordsY + 32.5998);
    ctx.bezierCurveTo(coordsX + 4, coordsY + 32.4998, coordsX + 4, coordsY + 32.3998, coordsX + 4, coordsY + 32.2998);
    ctx.bezierCurveTo(coordsX + 4.7, coordsY + 30.1998, coordsX + 6.1, coordsY + 28.2998, coordsX + 8.1, coordsY + 26.5998);
    ctx.lineTo(coordsX + 14.6, coordsY + 20.9998);
    ctx.bezierCurveTo(coordsX + 17.2, coordsY + 18.8998, coordsX + 19.8, coordsY + 17.7998, coordsX + 22.6, coordsY + 17.7998);
    ctx.lineTo(coordsX + 41.6, coordsY + 17.7998);
    ctx.bezierCurveTo(coordsX + 46.2, coordsY + 17.7998, coordsX + 48.8, coordsY + 20.1998, coordsX + 48.8, coordsY + 24.4998);
    ctx.lineTo(coordsX + 48.8, coordsY + 39.4998);
    ctx.bezierCurveTo(coordsX + 48.9, coordsY + 43.7998, coordsX + 46.2, coordsY + 46.2998, coordsX + 41.6, coordsY + 46.2998);
    ctx.closePath();
    ctx.moveTo(coordsX + 9, coordsY + 44.0998);
    ctx.bezierCurveTo(coordsX + 9.6, coordsY + 44.1998, coordsX + 10.2, coordsY + 44.2998, coordsX + 10.8, coordsY + 44.2998);
    ctx.lineTo(coordsX + 41.6, coordsY + 44.2998);
    ctx.bezierCurveTo(coordsX + 45.1, coordsY + 44.2998, coordsX + 46.8, coordsY + 42.6998, coordsX + 46.8, coordsY + 39.5998);
    ctx.lineTo(coordsX + 46.8, coordsY + 24.5998);
    ctx.bezierCurveTo(coordsX + 46.8, coordsY + 21.4998, coordsX + 45, coordsY + 19.8998, coordsX + 41.6, coordsY + 19.8998);
    ctx.lineTo(coordsX + 22.6, coordsY + 19.8998);
    ctx.bezierCurveTo(coordsX + 20.3, coordsY + 19.8998, coordsX + 18.1, coordsY + 20.7998, coordsX + 15.9, coordsY + 22.5998);
    ctx.lineTo(coordsX + 9.4, coordsY + 28.1998);
    ctx.bezierCurveTo(coordsX + 7.7, coordsY + 29.5998, coordsX + 6.5, coordsY + 31.1998, coordsX + 6, coordsY + 32.7998);
    ctx.bezierCurveTo(coordsX + 6.1, coordsY + 36.6998, coordsX + 7.2, coordsY + 40.5998, coordsX + 9, coordsY + 44.0998);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 31.9, coordsY + 59.9);
    ctx.bezierCurveTo(coordsX + 16.5, coordsY + 59.9, coordsX + 4, coordsY + 47.3, coordsX + 4, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 4, coordsY + 16.5, coordsX + 16.5, coordsY + 4, coordsX + 31.9, coordsY + 4);
    ctx.bezierCurveTo(coordsX + 47.3, coordsY + 4, coordsX + 59.8, coordsY + 16.5, coordsX + 59.8, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 59.8, coordsY + 47.3, coordsX + 47.3, coordsY + 59.9, coordsX + 31.9, coordsY + 59.9);
    ctx.closePath();
    ctx.moveTo(coordsX + 31.9, coordsY + 6);
    ctx.bezierCurveTo(coordsX + 17.6, coordsY + 6, coordsX + 6, coordsY + 17.6, coordsX + 6, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 6, coordsY + 46.2, coordsX + 17.6, coordsY + 57.8, coordsX + 31.9, coordsY + 57.8);
    ctx.bezierCurveTo(coordsX + 46.2, coordsY + 57.8, coordsX + 57.8, coordsY + 46.2, coordsX + 57.8, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 57.8, coordsY + 17.6, coordsX + 46.2, coordsY + 6, coordsX + 31.9, coordsY + 6);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
    ctx.restore();
  },
  line: (ctx: CanvasRenderingContext2D, coordsX: number = 0, coordsY: number = 0) => {
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.font = "15px ''";
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.font = "   15px ''";
    ctx.save();
    ctx.fillStyle = "white";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.arc(coordsX + 32, coordsY + 32, 27, 0, 6.283185307179586, false);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.font = "   15px ''";
    ctx.translate(4, 4);
    ctx.beginPath();
    ctx.moveTo(coordsX + 0, coordsY + 0);
    ctx.lineTo(coordsX + 55.9, coordsY + 0);
    ctx.bezierCurveTo(coordsX + 55.9, coordsY + 0, coordsX + 55.9, coordsY + 0, coordsX + 55.9, coordsY + 0);
    ctx.lineTo(coordsX + 55.9, coordsY + 55.9);
    ctx.bezierCurveTo(coordsX + 55.9, coordsY + 55.9, coordsX + 55.9, coordsY + 55.9, coordsX + 55.9, coordsY + 55.9);
    ctx.lineTo(coordsX + 0, coordsY + 55.9);
    ctx.bezierCurveTo(coordsX + 0, coordsY + 55.9, coordsX + 0, coordsY + 55.9, coordsX + 0, coordsY + 55.9);
    ctx.lineTo(coordsX + 0, coordsY + 0);
    ctx.bezierCurveTo(coordsX + 0, coordsY + 0, coordsX + 0, coordsY + 0, coordsX + 0, coordsY + 0);
    ctx.closePath();
    ctx.translate(-4, -4);
    ctx.clip();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 7.2, coordsY + 42.5);
    ctx.bezierCurveTo(coordsX + 6.9, coordsY + 42.5, coordsX + 6.6, coordsY + 42.3, coordsX + 6.4, coordsY + 42.1);
    ctx.bezierCurveTo(coordsX + 6.1, coordsY + 41.6, coordsX + 6.2, coordsY + 41, coordsX + 6.7, coordsY + 40.7);
    ctx.lineTo(coordsX + 49.2,coordsY +  11.2);
    ctx.bezierCurveTo(coordsX + 49.7, coordsY + 10.9, coordsX + 50.3, coordsY + 11, coordsX + 50.6, coordsY + 11.5);
    ctx.bezierCurveTo(coordsX + 50.9, coordsY + 12, coordsX + 50.8, coordsY + 12.6, coordsX + 50.3, coordsY + 12.9);
    ctx.lineTo(coordsX + 7.8, coordsY + 42.3);
    ctx.bezierCurveTo(coordsX + 7.6, coordsY + 42.4, coordsX + 7.4, coordsY + 42.5, coordsX + 7.2, coordsY + 42.5);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 17.7, coordsY + 51.1001);
    ctx.bezierCurveTo(coordsX + 17.4, coordsY + 51.1001, coordsX + 17.2, coordsY + 51.0001, coordsX + 17, coordsY + 50.8001);
    ctx.bezierCurveTo(coordsX + 16.6, coordsY + 50.4001, coordsX + 16.6, coordsY + 49.8001, coordsX + 17, coordsY + 49.4001);
    ctx.lineTo(coordsX + 43.3, coordsY + 24.9001);
    ctx.bezierCurveTo(coordsX + 43.7, coordsY + 24.5001, coordsX + 44.3, coordsY + 24.5001, coordsX + 44.7, coordsY + 24.9001);
    ctx.bezierCurveTo(coordsX + 45.1, coordsY + 25.3001, coordsX + 45.1, coordsY + 25.9001, coordsX + 44.7, coordsY + 26.3001);
    ctx.lineTo(coordsX + 18.3, coordsY + 50.8001);
    ctx.bezierCurveTo(coordsX + 18.1, coordsY + 51.0001, coordsX + 17.9, coordsY + 51.1001, coordsX + 17.7, coordsY + 51.1001);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 48.5, coordsY + 22.4);
    ctx.bezierCurveTo(coordsX + 48.2, coordsY + 22.4, coordsX + 48, coordsY + 22.3, coordsX + 47.8, coordsY + 22.1);
    ctx.bezierCurveTo(coordsX + 47.4, coordsY + 21.7, coordsX + 47.4, coordsY + 21.1, coordsX + 47.8, coordsY + 20.7);
    ctx.lineTo(coordsX + 51, coordsY + 17.8);
    ctx.bezierCurveTo(coordsX + 51.4, coordsY + 17.4, coordsX + 52, coordsY + 17.4, coordsX + 52.4, coordsY + 17.8);
    ctx.bezierCurveTo(coordsX + 52.8, coordsY + 18.2, coordsX + 52.8, coordsY + 18.8, coordsX + 52.4, coordsY + 19.2);
    ctx.lineTo(coordsX + 49.2, coordsY + 22.1);
    ctx.bezierCurveTo(coordsX + 49, coordsY + 22.3, coordsX + 48.8, coordsY + 22.4, coordsX + 48.5, coordsY + 22.4);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 29.5, coordsY + 52.3002);
    ctx.bezierCurveTo(coordsX + 29.2, coordsY + 52.3002, coordsX + 29, coordsY + 52.2002, coordsX + 28.8, coordsY + 52.0002);
    ctx.bezierCurveTo(coordsX + 28.4, coordsY + 51.6002, coordsX + 28.4, coordsY + 51.0002, coordsX + 28.8, coordsY + 50.6002);
    ctx.lineTo(coordsX + 48.2, coordsY + 31.0002);
    ctx.bezierCurveTo(coordsX + 48.6, coordsY + 30.6002, coordsX + 49.2, coordsY + 30.6002, coordsX + 49.6, coordsY + 31.0002);
    ctx.bezierCurveTo(coordsX + 50, coordsY + 31.4002, coordsX + 50, coordsY + 32.0002, coordsX + 49.6, coordsY + 32.4002);
    ctx.lineTo(coordsX + 30.3, coordsY + 52.0002);
    ctx.bezierCurveTo(coordsX + 30.1, coordsY + 52.2002, coordsX + 29.8, coordsY + 52.3002, coordsX + 29.5, coordsY + 52.3002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 52.7, coordsY + 45.1999);
    ctx.bezierCurveTo(coordsX + 52.4, coordsY + 45.1999, coordsX + 52.1, coordsY + 45.0999, coordsX + 51.9, coordsY + 44.7999);
    ctx.lineTo(coordsX + 24.4, coordsY + 11.4999);
    ctx.bezierCurveTo(coordsX + 24, coordsY + 10.9999, coordsX + 24.1, coordsY + 10.3999, coordsX + 24.5, coordsY + 10.0999);
    ctx.bezierCurveTo(coordsX + 24.9, coordsY + 9.69985, coordsX + 25.6, coordsY + 9.79985, coordsX + 25.9, coordsY + 10.1999);
    ctx.lineTo(coordsX + 53.5, coordsY + 43.5999);
    ctx.bezierCurveTo(coordsX + 53.9, coordsY + 43.9999, coordsX + 53.8, coordsY + 44.6999, coordsX + 53.4, coordsY + 44.9999);
    ctx.bezierCurveTo(coordsX + 53.2, coordsY + 45.0999, coordsX + 53, coordsY + 45.1999, coordsX + 52.7, coordsY + 45.1999);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 48.1, coordsY + 54.4);
    ctx.bezierCurveTo(coordsX + 47.8, coordsY + 54.4, coordsX + 47.5, coordsY + 54.3, coordsX + 47.3, coordsY + 54.1);
    ctx.lineTo(coordsX + 17.2, coordsY + 21.4);
    ctx.bezierCurveTo(coordsX + 16.8, coordsY + 21, coordsX + 16.8, coordsY + 20.4, coordsX + 17.3, coordsY + 20);
    ctx.bezierCurveTo(coordsX + 17.7, coordsY + 19.6, coordsX + 18.3, coordsY + 19.6, coordsX + 18.7, coordsY + 20.1);
    ctx.lineTo(coordsX + 48.8, coordsY + 52.7);
    ctx.bezierCurveTo(coordsX + 49.2, coordsY + 53.1, coordsX + 49.2, coordsY + 53.7, coordsX + 48.7, coordsY + 54.1);
    ctx.bezierCurveTo(coordsX + 48.5, coordsY + 54.3, coordsX + 48.3, coordsY + 54.4, coordsX + 48.1, coordsY + 54.4);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 31.9, coordsY + 59.9);
    ctx.bezierCurveTo(coordsX + 16.5, coordsY + 59.9, coordsX + 4, coordsY + 47.3, coordsX + 4, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 4, coordsY + 16.5, coordsX + 16.5, coordsY + 4, coordsX + 31.9, coordsY + 4);
    ctx.bezierCurveTo(coordsX + 47.3, coordsY + 4, coordsX + 59.8, coordsY + 16.5, coordsX + 59.8, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 59.8, coordsY + 47.3, coordsX + 47.3, coordsY + 59.9, coordsX + 31.9, coordsY + 59.9);
    ctx.closePath();
    ctx.moveTo(coordsX + 31.9, coordsY + 6);
    ctx.bezierCurveTo(coordsX + 17.6, coordsY + 6, coordsX + 6, coordsY + 17.6, coordsX + 6, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 6, coordsY + 46.2, coordsX + 17.6, coordsY + 57.8, coordsX + 31.9, coordsY + 57.8);
    ctx.bezierCurveTo(coordsX + 46.2, coordsY + 57.8, coordsX + 57.8, coordsY + 46.2, coordsX + 57.8, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 57.8, coordsY + 17.6, coordsX + 46.2, coordsY + 6, coordsX + 31.9, coordsY + 6);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
    ctx.restore();
  },
  circle: (ctx: CanvasRenderingContext2D, coordsX: number = 0, coordsY: number = 0) => {
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.font = "15px ''";
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.font = "   15px ''";
    ctx.save();
    ctx.fillStyle = "white";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.arc(coordsX + 32, coordsY + 32, 27, 0, 6.283185307179586, false);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.font = "   15px ''";
    ctx.translate(4, 4);
    ctx.beginPath();
    ctx.moveTo(coordsX + 0, coordsY + 0);
    ctx.lineTo(coordsX + 55.9, coordsY + 0);
    ctx.bezierCurveTo(coordsX + 55.9, coordsY + 0, coordsX + 55.9, coordsY + 0, coordsX + 55.9, coordsY + 0);
    ctx.lineTo(coordsX + 55.9, coordsY + 55.9);
    ctx.bezierCurveTo(coordsX + 55.9, coordsY + 55.9, coordsX + 55.9, coordsY + coordsX + 55.9, 55.9, coordsY + 55.9);
    ctx.lineTo(coordsX + 0, coordsY + 55.9);
    ctx.bezierCurveTo(coordsX + 0, coordsY + 55.9, coordsX + 0, coordsY + 55.9, coordsX + 0, coordsY + 55.9);
    ctx.lineTo(coordsX + 0, coordsY + 0);
    ctx.bezierCurveTo(coordsX + 0, coordsY + 0, coordsX + 0, coordsY + 0, coordsX + 0, coordsY + 0);
    ctx.closePath();
    ctx.translate(-4, -4);
    ctx.clip();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 48.1, coordsY + 54.1001);
    ctx.lineTo(coordsX + 15.4, coordsY + 54.1001);
    ctx.bezierCurveTo(coordsX + 14.8, coordsY + 54.1001, coordsX + 14.4, coordsY + 53.7001, coordsX + 14.4, coordsY + 53.1001);
    ctx.bezierCurveTo(coordsX + 14.4, coordsY + 52.5001, coordsX + 14.8, coordsY + 52.1001, coordsX + 15.4, coordsY + 52.1001);
    ctx.lineTo(coordsX + 48.1, coordsY + 52.1001);
    ctx.bezierCurveTo(coordsX + 48.7, coordsY + 52.1001, coordsX + 49.1, coordsY + 52.5001, coordsX + 49.1, coordsY + 53.1001);
    ctx.bezierCurveTo(coordsX + 49.1, coordsY + 53.7001, coordsX + 48.7, coordsY + 54.1001, coordsX + 48.1, coordsY + 54.1001);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 31.9, coordsY + 49.2002);
    ctx.bezierCurveTo(coordsX + 29.3, coordsY + 49.2002, coordsX + 26.8, coordsY + 48.8002, coordsX + 24.4, coordsY + 47.9002);
    ctx.bezierCurveTo(coordsX + 24, coordsY + 47.8002, coordsX + 23.6, coordsY + 47.7002, coordsX + 23.3, coordsY + 47.7002);
    ctx.lineTo(coordsX + 9.8, coordsY + 47.7002);
    ctx.bezierCurveTo(coordsX + 9.2, coordsY + 47.7002, coordsX + 8.8, coordsY + 47.3002, coordsX + 8.8, coordsY + 46.7002);
    ctx.bezierCurveTo(coordsX + 8.8, coordsY + 46.1002, coordsX + 9.2, coordsY + 45.7002, coordsX + 9.8, coordsY + 45.7002);
    ctx.lineTo(coordsX + 23.4, coordsY + 45.7002);
    ctx.bezierCurveTo(coordsX + 24, coordsY + 45.7002, coordsX + 24.6, coordsY + 45.8002, coordsX + 25.2, coordsY + 46.0002);
    ctx.bezierCurveTo(coordsX + 29.6, coordsY + 47.6002, coordsX + 34.4, coordsY + 47.6002, coordsX + 38.8, coordsY + 46.0002);
    ctx.bezierCurveTo(coordsX + 39.4, coordsY + 45.8002, coordsX + 40, coordsY + 45.7002, coordsX + 40.6, coordsY + 45.7002);
    ctx.lineTo(coordsX + 54.2, coordsY + 45.7002);
    ctx.bezierCurveTo(coordsX + 54.8, coordsY + 45.7002, coordsX + 55.2, coordsY + 46.1002, coordsX + 55.2, coordsY + 46.7002);
    ctx.bezierCurveTo(coordsX + 55.2, coordsY + 47.3002, coordsX + 54.8, coordsY + 47.7002, coordsX + 54.2, coordsY + 47.7002);
    ctx.lineTo(coordsX + 40.6, coordsY + 47.7002);
    ctx.bezierCurveTo(coordsX + 40.2, coordsY + 47.7002, coordsX + 39.9, coordsY + 47.8002, coordsX + 39.5, coordsY + 47.9002);
    ctx.bezierCurveTo(coordsX + 37, coordsY + 48.8002, coordsX + 34.5, coordsY + 49.2002, coordsX + 31.9, coordsY + 49.2002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 31.9, coordsY + 45.2002);
    ctx.bezierCurveTo(coordsX + 28.2, coordsY + 45.2002, coordsX + 24.6, coordsY + 44.1002, coordsX + 21.6, coordsY + 41.9002);
    ctx.bezierCurveTo(coordsX + 20.9, coordsY + 41.4002, coordsX + 20.2, coordsY + 41.2002, coordsX + 19.4, coordsY + 41.2002);
    ctx.lineTo(coordsX + 9.8, coordsY + 41.2002);
    ctx.bezierCurveTo(coordsX + 9.2, coordsY + 41.2002, coordsX + 8.8, coordsY + 40.8002, coordsX + 8.8, coordsY + 40.2002);
    ctx.bezierCurveTo(coordsX + 8.8, coordsY + 39.6002, coordsX + 9.2, coordsY + 39.2002, coordsX + 9.8, coordsY + 39.2002);
    ctx.lineTo(coordsX + 19.4, coordsY + 39.2002);
    ctx.bezierCurveTo(coordsX + 20.6, coordsY + 39.2002, coordsX + 21.8, coordsY + 39.6002, coordsX + 22.8, coordsY + 40.3002);
    ctx.bezierCurveTo(coordsX + 25.5, coordsY + 42.2002, coordsX + 28.7, coordsY + 43.2002, coordsX + 32, coordsY + 43.2002);
    ctx.bezierCurveTo(coordsX + 35.3, coordsY + 43.2002, coordsX + 38.5, coordsY + 42.2002, coordsX + 41.2, coordsY + 40.3002);
    ctx.bezierCurveTo(coordsX + 42.2, coordsY + 39.6002, coordsX + 43.4, coordsY + 39.2002, coordsX + 44.6, coordsY + 39.2002);
    ctx.lineTo(coordsX + 54.2, coordsY + 39.2002);
    ctx.bezierCurveTo(coordsX + 54.8, coordsY + 39.2002, coordsX + 55.2, coordsY + 39.6002, coordsX + 55.2, coordsY + 40.2002);
    ctx.bezierCurveTo(coordsX + 55.2, coordsY + 40.8002, coordsX + 54.8, coordsY + 41.2002, coordsX + 54.2, coordsY + 41.2002);
    ctx.lineTo(coordsX + 44.6, coordsY + 41.2002);
    ctx.bezierCurveTo(coordsX + 43.8, coordsY + 41.2002, coordsX + 43, coordsY + 41.4002, coordsX + 42.4, coordsY + 41.9002);
    ctx.bezierCurveTo(coordsX + 39.2, coordsY + 44.1002, coordsX + 35.7, coordsY + 45.2002, coordsX + 31.9, coordsY + 45.2002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 22.2, coordsY + 24.1002);
    ctx.bezierCurveTo(coordsX + 21.9, coordsY + 24.1002, coordsX + 21.7, coordsY + 24.0002, coordsX + 21.5, coordsY + 23.8002);
    ctx.lineTo(coordsX + 18.8, coordsY + 21.1002);
    ctx.bezierCurveTo(coordsX + 18.6, coordsY + 20.9002, coordsX + 18.5, coordsY + 20.7002, coordsX + 18.5, coordsY + 20.4002);
    ctx.bezierCurveTo(coordsX + 18.5, coordsY + 20.1002, coordsX + 18.6, coordsY + 19.9002, coordsX + 18.8, coordsY + 19.7002);
    ctx.lineTo(coordsX + 24, coordsY + 14.5002);
    ctx.bezierCurveTo(coordsX + 24.4, coordsY + 14.1002, coordsX + 25, coordsY + 14.1002, coordsX + 25.4, coordsY + 14.5002);
    ctx.lineTo(coordsX + 28, coordsY + 17.1002);
    ctx.bezierCurveTo(coordsX + 28.2, coordsY + 17.3002, coordsX + 28.3, coordsY + 17.7002, coordsX + 28.3, coordsY + 18.0002);
    ctx.bezierCurveTo(coordsX + 28.3, coordsY + 18.3002, coordsX + 28, coordsY + 18.6002, coordsX + 27.8, coordsY + 18.7002);
    ctx.bezierCurveTo(coordsX + 26.8, coordsY + 19.2002, coordsX + 26, coordsY + 19.8002, coordsX + 25.2, coordsY + 20.5002);
    ctx.bezierCurveTo(coordsX + 24.3, coordsY + 21.4002, coordsX + 23.7, coordsY + 22.4002, coordsX + 23.2, coordsY + 23.5002);
    ctx.bezierCurveTo(coordsX + 23.1, coordsY + 23.8002, coordsX + 22.8, coordsY + 24.0002, coordsX + 22.5, coordsY + 24.1002);
    ctx.bezierCurveTo(coordsX + 22.3, coordsY + 24.1002, coordsX + 22.2, coordsY + 24.1002, coordsX + 22.2, coordsY + 24.1002);
    ctx.closePath();
    ctx.moveTo(coordsX + 20.9, coordsY + 20.4002);
    ctx.lineTo(coordsX + 21.9, coordsY + 21.4002);
    ctx.bezierCurveTo(coordsX + 22.4, coordsY + 20.5002, coordsX + 23, coordsY + 19.8002, coordsX + 23.7, coordsY + 19.1002);
    ctx.bezierCurveTo(coordsX + 24.3, coordsY + 18.5002, coordsX + 24.9, coordsY + 18.0002, coordsX + 25.6, coordsY + 17.6002);
    ctx.lineTo(coordsX + 24.7, coordsY + 16.7002);
    ctx.lineTo(coordsX + 20.9, coordsY + 20.4002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 31.9, coordsY + 39.1002);
    ctx.bezierCurveTo(coordsX + 28.8, coordsY + 39.1002, coordsX + 25.9, coordsY + 37.9002, coordsX + 23.6, coordsY + 35.7002);
    ctx.bezierCurveTo(coordsX + 21.3, coordsY + 33.5002, coordsX + 20.2, coordsY + 30.6002, coordsX + 20.2, coordsY + 27.4002);
    ctx.bezierCurveTo(coordsX + 20.2, coordsY + 24.2002, coordsX + 21.4, coordsY + 21.4002, coordsX + 23.6, coordsY + 19.1002);
    ctx.bezierCurveTo(coordsX + 25.8, coordsY + 16.8002, coordsX + 28.7, coordsY + 15.7002, coordsX + 31.9, coordsY + 15.7002);
    ctx.bezierCurveTo(coordsX + 35.1, coordsY + 15.7002, coordsX + 37.9, coordsY + 16.9002, coordsX + 40.2, coordsY + 19.1002);
    ctx.bezierCurveTo(coordsX + 42.5, coordsY + 21.3002, coordsX + 43.6, coordsY + 24.2002, coordsX + 43.6, coordsY + 27.4002);
    ctx.bezierCurveTo(coordsX + 43.6, coordsY + 30.6002, coordsX + 42.4, coordsY + 33.4002, coordsX + 40.2, coordsY + 35.7002);
    ctx.bezierCurveTo(coordsX + 38, coordsY + 38.0002, coordsX + 35.1, coordsY + 39.1002, coordsX + 31.9, coordsY + 39.1002);
    ctx.closePath();
    ctx.moveTo(coordsX + 31.9, coordsY + 17.7002);
    ctx.bezierCurveTo(coordsX + 29.4, coordsY + 17.7002, coordsX + 26.9, coordsY + 18.6002, coordsX + 25.1, coordsY + 20.5002);
    ctx.bezierCurveTo(coordsX + 21.3, coordsY + 24.3002, coordsX + 21.3, coordsY + 30.4002, coordsX + 25.1, coordsY + 34.2002);
    ctx.bezierCurveTo(coordsX + 28.9, coordsY + 38.0002, coordsX + 35, coordsY + 38.0002, coordsX + 38.8, coordsY + 34.2002);
    ctx.bezierCurveTo(coordsX + 42.6, coordsY + 30.4002, coordsX + 42.6, coordsY + 24.3002, coordsX + 38.8, coordsY + 20.5002);
    ctx.bezierCurveTo(coordsX + 36.9, coordsY + 18.7002, coordsX + 34.4, coordsY + 17.7002, coordsX + 31.9, coordsY + 17.7002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 31.9, coordsY + 35.0999);
    ctx.bezierCurveTo(coordsX + 28.5, coordsY + 35.0999, coordsX + 25.4, coordsY + 32.8999, coordsX + 24.5, coordsY + 29.4999);
    ctx.bezierCurveTo(coordsX + 24.4, coordsY + 28.9999, coordsX + 24.7, coordsY + 28.3999, coordsX + 25.2, coordsY + 28.2999);
    ctx.bezierCurveTo(coordsX + 25.7, coordsY + 28.1999, coordsX + 26.3, coordsY + 28.4999, coordsX + 26.4, coordsY + 28.9999);
    ctx.bezierCurveTo(coordsX + 27.2, coordsY + 31.9999, coordsX + 30.4, coordsY + 33.7999, coordsX + 33.4, coordsY + 32.9999);
    ctx.bezierCurveTo(coordsX + 33.9, coordsY + 32.8999, coordsX + 34.5, coordsY + 33.1999, coordsX + 34.6, coordsY + 33.6999);
    ctx.bezierCurveTo(coordsX + 34.7, coordsY + 34.1999, coordsX + 34.4, coordsY + 34.7999, coordsX + 33.9, coordsY + 34.8999);
    ctx.bezierCurveTo(coordsX + 33.3, coordsY + 34.9999, coordsX + 32.6, coordsY + 35.0999, coordsX + 31.9, coordsY + 35.0999);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 22.1, coordsY + 18.7999);
    ctx.bezierCurveTo(coordsX + 21.8, coordsY + 18.7999, coordsX + 21.6, coordsY + 18.6999, coordsX + 21.4, coordsY + 18.4999);
    ctx.lineTo(coordsX + 14.5, coordsY + 11.5999);
    ctx.bezierCurveTo(coordsX + 14.1, coordsY + 11.1999, coordsX + 14.1, coordsY + 10.5999, coordsX + 14.5, coordsY + 10.1999);
    ctx.bezierCurveTo(coordsX + 14.9, coordsY + 9.7999, coordsX + 15.5, coordsY + 9.7999, coordsX + 15.9, coordsY + 10.1999);
    ctx.lineTo(coordsX + 22.8, coordsY + 17.0999);
    ctx.bezierCurveTo(coordsX + 23.2, coordsY + 17.4999, coordsX + 23.2, coordsY + 18.0999, coordsX + 22.8, coordsY + 18.4999);
    ctx.bezierCurveTo(coordsX + 22.6, coordsY + 18.6999, coordsX + 22.3, coordsY + 18.7999, coordsX + 22.1, coordsY + 18.7999);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 47.5, coordsY + 36.6002);
    ctx.bezierCurveTo(coordsX + 47.1, coordsY + 36.6002, coordsX + 46.8, coordsY + 36.4002, coordsX + 46.6, coordsY + 36.1002);
    ctx.bezierCurveTo(coordsX + 46.4, coordsY + 35.6002, coordsX + 46.5, coordsY + 35.0002, coordsX + 47, coordsY + 34.8002);
    ctx.lineTo(coordsX + 51.6, coordsY + 32.4002);
    ctx.bezierCurveTo(coordsX + 52.1, coordsY + 32.1002, coordsX + 52.7, coordsY + 32.3002, coordsX + 52.9, coordsY + 32.8002);
    ctx.bezierCurveTo(coordsX + 53.1, coordsY + 33.3002, coordsX + 53, coordsY + 33.9002, coordsX + 52.5, coordsY + 34.1002);
    ctx.lineTo(coordsX + 47.9, coordsY + 36.5002);
    ctx.bezierCurveTo(coordsX + 47.8, coordsY + 36.5002, coordsX + 47.6, coordsY + 36.6002, coordsX + 47.5, coordsY + 36.6002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 46.4, coordsY + 32.9999);
    ctx.bezierCurveTo(coordsX + 46.1, coordsY + 32.9999, coordsX + 45.8, coordsY + 32.8999, coordsX + 45.6, coordsY + 32.5999);
    ctx.bezierCurveTo(coordsX + 45.2, coordsY + 32.1999, coordsX + 45.3, coordsY + 31.4999, coordsX + 45.7, coordsY + 31.1999);
    ctx.lineTo(coordsX + 48.1, coordsY + 29.0999);
    ctx.bezierCurveTo(coordsX + 48.5, coordsY + 28.6999, coordsX + 49.2, coordsY + 28.7999, coordsX + 49.5, coordsY + 29.1999);
    ctx.bezierCurveTo(coordsX + 49.9, coordsY + 29.5999, coordsX + 49.8, coordsY + 30.2999, coordsX + 49.4, coordsY + 30.5999);
    ctx.lineTo(coordsX + 47, coordsY + 32.6999);
    ctx.bezierCurveTo(coordsX + 46.8, coordsY + 32.8999, coordsX + 46.6, coordsY + 32.9999, coordsX + 46.4, coordsY + 32.9999);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 31.9, coordsY + 59.9);
    ctx.bezierCurveTo(coordsX + 16.5, coordsY + 59.9, coordsX + 4, coordsY + 47.3, coordsX + 4, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 4, coordsY + 16.5, coordsX + 16.5, coordsY + 4, coordsX + 31.9, coordsY + 4);
    ctx.bezierCurveTo(coordsX + 47.3, coordsY + 4, coordsX + 59.8, coordsY + 16.5, coordsX + 59.8, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 59.8, coordsY + 47.3, coordsX + 47.3, coordsY + 59.9, coordsX + 31.9, coordsY + 59.9);
    ctx.closePath();
    ctx.moveTo(coordsX + 31.9, coordsY + 6);
    ctx.bezierCurveTo(coordsX + 17.6, coordsY + 6, coordsX + 6, coordsY + 17.6, coordsX + 6, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 6, coordsY + 46.2, coordsX + 17.6, coordsY + 57.8, coordsX + 31.9, coordsY + 57.8);
    ctx.bezierCurveTo(coordsX + 46.2, coordsY + 57.8, coordsX + 57.8, coordsY + 46.2, coordsX + 57.8, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 57.8, coordsY + 17.6, coordsX + 46.2, coordsY + 6, coordsX + 31.9, coordsY + 6);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
    ctx.restore();
  },
  color: (ctx: CanvasRenderingContext2D, coordsX: number = 0, coordsY: number = 0) => {
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.font = "15px ''";
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.font = "   15px ''";
    ctx.save();
    ctx.fillStyle = "white";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.arc(coordsX + 32, coordsY + 32, 27, 0, 6.283185307179586, false);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.font = "   15px ''";
    ctx.translate(4, 4);
    ctx.beginPath();
    ctx.moveTo(coordsX + 0, coordsY + 0);
    ctx.lineTo(coordsX + 55.9, coordsY + 0);
    ctx.bezierCurveTo(coordsX + 55.9, coordsY + 0, coordsX + 55.9, coordsY + 0, coordsX + 55.9, coordsY + 0);
    ctx.lineTo(coordsX + 55.9, coordsY + 55.9);
    ctx.bezierCurveTo(coordsX + 55.9, coordsY + 55.9, coordsX + 55.9, coordsY + 55.9, coordsX + 55.9, coordsY + 55.9);
    ctx.lineTo(coordsX + 0, coordsY + 55.9);
    ctx.bezierCurveTo(coordsX + 0, coordsY + 55.9, coordsX + 0, coordsY + 55.9, coordsX + 0, coordsY + 55.9);
    ctx.lineTo(coordsX + 0, coordsY + 0);
    ctx.bezierCurveTo(coordsX + 0, coordsY + 0, coordsX + 0, coordsY + 0, coordsX + 0, coordsY + 0);
    ctx.closePath();
    ctx.translate(-4, -4);
    ctx.clip();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 50.2, coordsY + 52.6001);
    ctx.lineTo(coordsX + 13.6, coordsY + 52.6001);
    ctx.bezierCurveTo(coordsX + 13, coordsY + 52.6001, coordsX + 12.6, coordsY + 52.2001, coordsX + 12.6, coordsY + 51.6001);
    ctx.bezierCurveTo(coordsX + 12.6, coordsY + 51.0001, coordsX + 13, coordsY + 50.6001, coordsX + 13.6, coordsY + 50.6001);
    ctx.lineTo(coordsX + 50.2, coordsY + 50.6001);
    ctx.bezierCurveTo(coordsX + 50.8, coordsY + 50.6001, coordsX + 51.2, coordsY + 51.0001, coordsX + 51.2, coordsY + 51.6001);
    ctx.bezierCurveTo(coordsX + 51.2, coordsY + 52.2001, coordsX + 50.8, coordsY + 52.6001, coordsX + 50.2, coordsY + 52.6001);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 54.5, coordsY + 47.2002);
    ctx.lineTo(coordsX + 9.20001, coordsY + 47.2002);
    ctx.bezierCurveTo(coordsX + 8.60001, coordsY + 47.2002, coordsX + 8.20001, coordsY + 46.8002, coordsX + 8.20001, coordsY + 46.2002);
    ctx.bezierCurveTo(coordsX + 8.20001, coordsY + 45.6002, coordsX + 8.60001, coordsY + 45.2002, coordsX + 9.20001, coordsY + 45.2002);
    ctx.lineTo(coordsX + 54.5, coordsY + 45.2002);
    ctx.bezierCurveTo(coordsX + 55.1, coordsY + 45.2002, coordsX + 55.5, coordsY + 45.6002, coordsX + 55.5, coordsY + 46.2002);
    ctx.bezierCurveTo(coordsX + 55.5, coordsY + 46.8002, coordsX + 55, coordsY + 47.2002, coordsX + 54.5, coordsY + 47.2002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 14.5, coordsY + 24.7002);
    ctx.lineTo(coordsX + 9.39999, coordsY + 24.7002);
    ctx.bezierCurveTo(coordsX + 8.79999, coordsY + 24.7002, coordsX + 8.39999, coordsY + 24.3002, coordsX + 8.39999, coordsY + 23.7002);
    ctx.bezierCurveTo(coordsX + 8.39999, coordsY + 23.1002, coordsX + 8.79999, coordsY + 22.7002, coordsX + 9.39999, coordsY + 22.7002);
    ctx.lineTo(coordsX + 14.5, coordsY + 22.7002);
    ctx.bezierCurveTo(coordsX + 15.1, coordsY + 22.7002, coordsX + 15.5, coordsY + 23.1002, coordsX + 15.5, coordsY + 23.7002);
    ctx.bezierCurveTo(coordsX + 15.5, coordsY + 24.3002, coordsX + 15, coordsY + 24.7002, coordsX + 14.5, coordsY + 24.7002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 11.5, coordsY + 30.3999);
    ctx.lineTo(coordsX + 8.39999, coordsY + 30.3999);
    ctx.bezierCurveTo(coordsX + 7.79999, coordsY + 30.3999, coordsX + 7.39999, coordsY + 29.9999, coordsX + 7.39999, coordsY + 29.3999);
    ctx.bezierCurveTo(coordsX + 7.39999, coordsY + 28.7999, coordsX + 7.79999, coordsY + 28.3999, coordsX + 8.39999, coordsY + 28.3999);
    ctx.lineTo(coordsX + 11.5, coordsY + 28.3999);
    ctx.bezierCurveTo(coordsX + 12.1, coordsY + 28.3999, coordsX + 12.5, coordsY + 28.7999, coordsX + 12.5, coordsY + 29.3999);
    ctx.bezierCurveTo(coordsX + 12.5, coordsY + 29.9999, coordsX + 12, coordsY + 30.3999, coordsX + 11.5, coordsY + 30.3999);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 22.5, coordsY + 30.3999);
    ctx.lineTo(coordsX + 19.4, coordsY + 30.3999);
    ctx.bezierCurveTo(coordsX + 18.8, coordsY + 30.3999, coordsX + 18.4, coordsY + 29.9999, coordsX + 18.4, coordsY + 29.3999);
    ctx.bezierCurveTo(coordsX + 18.4, coordsY + 28.7999, coordsX + 18.8, coordsY + 28.3999, coordsX + 19.4, coordsY + 28.3999);
    ctx.lineTo(coordsX + 22.5, coordsY + 28.3999);
    ctx.bezierCurveTo(coordsX + 23.1, coordsY + 28.3999, coordsX + 23.5, coordsY + 28.7999, coordsX + 23.5, coordsY + 29.3999);
    ctx.bezierCurveTo(coordsX + 23.5, coordsY + 29.9999, coordsX + 23, coordsY + 30.3999, coordsX + 22.5, coordsY + 30.3999);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 33.5, coordsY + 30.3999);
    ctx.lineTo(coordsX + 30.4, coordsY + 30.3999);
    ctx.bezierCurveTo(coordsX + 29.8, coordsY + 30.3999, coordsX + 29.4, coordsY + 29.9999, coordsX + 29.4, coordsY + 29.3999);
    ctx.bezierCurveTo(coordsX + 29.4, coordsY + 28.7999, coordsX + 29.8, coordsY + 28.3999, coordsX + 30.4, coordsY + 28.3999);
    ctx.lineTo(coordsX + 33.5, coordsY + 28.3999);
    ctx.bezierCurveTo(coordsX + 34.1, coordsY + 28.3999, coordsX + 34.5, coordsY + 28.7999, coordsX + 34.5, coordsY + 29.3999);
    ctx.bezierCurveTo(coordsX + 34.5, coordsY + 29.9999, coordsX + 34, coordsY + 30.3999, coordsX + 33.5, coordsY + 30.3999);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 44.5, coordsY + 30.3999);
    ctx.lineTo(coordsX + 41.4, coordsY + 30.3999);
    ctx.bezierCurveTo(coordsX + 40.8, coordsY + 30.3999, coordsX + 40.4, coordsY + 29.9999, coordsX + 40.4, coordsY + 29.3999);
    ctx.bezierCurveTo(coordsX + 40.4, coordsY + 28.7999, coordsX + 40.8, coordsY + 28.3999, coordsX + 41.4, coordsY + 28.3999);
    ctx.lineTo(coordsX + 44.5, coordsY + 28.3999);
    ctx.bezierCurveTo(coordsX + 45.1, coordsY + 28.3999, coordsX + 45.5, coordsY + 28.7999, coordsX + 45.5, coordsY + 29.3999);
    ctx.bezierCurveTo(coordsX + 45.5, coordsY + 29.9999, coordsX + 45.1, coordsY + 30.3999, coordsX + 44.5, coordsY + 30.3999);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 55.5, coordsY + 30.3999);
    ctx.lineTo(coordsX + 52.4, coordsY + 30.3999);
    ctx.bezierCurveTo(coordsX + 51.8, coordsY + 30.3999, coordsX + 51.4, coordsY + 29.9999, coordsX + 51.4, coordsY + 29.3999);
    ctx.bezierCurveTo(coordsX + 51.4, coordsY + 28.7999, coordsX + 51.8, coordsY + 28.3999, coordsX + 52.4, coordsY + 28.3999);
    ctx.lineTo(coordsX + 55.5, coordsY + 28.3999);
    ctx.bezierCurveTo(coordsX + 56.1, coordsY + 28.3999, coordsX + 56.5, coordsY + 28.7999, coordsX + 56.5, coordsY + 29.3999);
    ctx.bezierCurveTo(coordsX + 56.5, coordsY + 29.9999, coordsX + 56.1, coordsY + 30.3999, coordsX + 55.5, coordsY + 30.3999);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 27.8, coordsY + 24.7002);
    ctx.lineTo(coordsX + 22.7, coordsY + 24.7002);
    ctx.bezierCurveTo(coordsX + 22.1, coordsY + 24.7002, coordsX + 21.7, coordsY + 24.3002, coordsX + 21.7, coordsY + 23.7002);
    ctx.bezierCurveTo(coordsX + 21.7, coordsY + 23.1002, coordsX + 22.1, coordsY + 22.7002, coordsX + 22.7, coordsY + 22.7002);
    ctx.lineTo(coordsX + 27.8, coordsY + 22.7002);
    ctx.bezierCurveTo(coordsX + 28.4, coordsY + 22.7002, coordsX + 28.8, coordsY + 23.1002, coordsX + 28.8, coordsY + 23.7002);
    ctx.bezierCurveTo(coordsX + 28.8, coordsY + 24.3002, coordsX + 28.4, coordsY + 24.7002, coordsX + 27.8, coordsY + 24.7002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 41.2, coordsY + 24.7002);
    ctx.lineTo(coordsX + 36, coordsY + 24.7002);
    ctx.bezierCurveTo(coordsX + 35.4, coordsY + 24.7002, coordsX + 35, coordsY + 24.3002, coordsX + 35, coordsY + 23.7002);
    ctx.bezierCurveTo(coordsX + 35, coordsY + 23.1002, coordsX + 35.4, coordsY + 22.7002, coordsX + 36, coordsY + 22.7002);
    ctx.lineTo(coordsX + 41.1, coordsY + 22.7002);
    ctx.bezierCurveTo(coordsX + 41.7, coordsY + 22.7002, coordsX + 42.1, coordsY + 23.1002, coordsX + 42.1, coordsY + 23.7002);
    ctx.bezierCurveTo(coordsX + 42.1, coordsY + 24.3002, coordsX + 41.7, coordsY + 24.7002, coordsX + 41.2, coordsY + 24.7002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 54.5, coordsY + 24.7002);
    ctx.lineTo(coordsX + 49.4, coordsY + 24.7002);
    ctx.bezierCurveTo(coordsX + 48.8, coordsY + 24.7002, coordsX + 48.4, coordsY + 24.3002, coordsX + 48.4, coordsY + 23.7002);
    ctx.bezierCurveTo(coordsX + 48.4, coordsY + 23.1002, coordsX + 48.8, coordsY + 22.7002, coordsX + 49.4, coordsY + 22.7002);
    ctx.lineTo(coordsX + 54.5, coordsY + 22.7002);
    ctx.bezierCurveTo(coordsX + 55.1, coordsY + 22.7002, coordsX + 55.5, coordsY + 23.1002, coordsX + 55.5, coordsY + 23.7002);
    ctx.bezierCurveTo(coordsX + 55.5, coordsY + 24.3002, coordsX + 55.1, coordsY + 24.7002, coordsX + 54.5, coordsY + 24.7002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 13.5, coordsY + 36);
    ctx.lineTo(coordsX + 8.39999, coordsY + 36);
    ctx.bezierCurveTo(coordsX + 7.79999, coordsY + 36, coordsX + 7.39999, coordsY + 35.6, coordsX + 7.39999, coordsY + 35);
    ctx.bezierCurveTo(coordsX + 7.39999, coordsY + 34.4, coordsX + 7.79999, coordsY + 34, coordsX + 8.39999, coordsY + 34);
    ctx.lineTo(coordsX + 13.5, coordsY + 34);
    ctx.bezierCurveTo(coordsX + 14.1, coordsY + 34, coordsX + 14.5, coordsY + 34.4, coordsX + 14.5, coordsY + 35);
    ctx.bezierCurveTo(coordsX + 14.5, coordsY + 35.6, coordsX + 14, coordsY + 36, coordsX + 13.5, coordsY + 36);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 27.5, coordsY + 36);
    ctx.lineTo(coordsX + 22.4, coordsY + 36);
    ctx.bezierCurveTo(coordsX + 21.8, coordsY + 36, coordsX + 21.4, coordsY + 35.6, coordsX + 21.4, coordsY + 35);
    ctx.bezierCurveTo(coordsX + 21.4, coordsY + 34.4, coordsX + 21.8, coordsY + 34, coordsX + 22.4, coordsY + 34);
    ctx.lineTo(coordsX + 27.5, coordsY + 34);
    ctx.bezierCurveTo(coordsX + 28.1, coordsY + 34, coordsX + 28.5, coordsY + 34.4, coordsX + 28.5, coordsY + 35);
    ctx.bezierCurveTo(coordsX + 28.5, coordsY + 35.6, coordsX + 28, coordsY + 36, coordsX + 27.5, coordsY + 36);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 41.5, coordsY + 36);
    ctx.lineTo(coordsX + 36.4, coordsY + 36);
    ctx.bezierCurveTo(coordsX + 35.8, coordsY + 36, coordsX + 35.4, coordsY + 35.6, coordsX + 35.4, coordsY + 35);
    ctx.bezierCurveTo(coordsX + 35.4, coordsY + 34.4, coordsX + 35.8, coordsY + 34, coordsX + 36.4, coordsY + 34);
    ctx.lineTo(coordsX + 41.5, coordsY + 34);
    ctx.bezierCurveTo(coordsX + 42.1, coordsY + 34, coordsX + 42.5, coordsY + 34.4, coordsX + 42.5, coordsY + 35);
    ctx.bezierCurveTo(coordsX + 42.5, coordsY + 35.6, coordsX + 42.1, coordsY + 36, coordsX + 41.5, coordsY + 36);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 55.5, coordsY + 36);
    ctx.lineTo(coordsX + 50.4, coordsY + 36);
    ctx.bezierCurveTo(coordsX + 49.8, coordsY + 36, coordsX + 49.4, coordsY + 35.6, coordsX + 49.4, coordsY + 35);
    ctx.bezierCurveTo(coordsX + 49.4, coordsY + 34.4, coordsX + 49.8, coordsY + 34, coordsX + 50.4, coordsY + 34);
    ctx.lineTo(coordsX + 55.5, coordsY + 34);
    ctx.bezierCurveTo(coordsX + 56.1, coordsY + 34, coordsX + 56.5, coordsY + 34.4, coordsX + 56.5, coordsY + 35);
    ctx.bezierCurveTo(coordsX + 56.5, coordsY + 35.6, coordsX + 56.1, coordsY + 36, coordsX + 55.5, coordsY + 36);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 19.5, coordsY + 19.1001);
    ctx.lineTo(coordsX + 9, coordsY + 19.1001);
    ctx.bezierCurveTo(coordsX + 8.4, coordsY + 19.1001, coordsX + 8, coordsY + 18.7001, coordsX + 8, coordsY + 18.1001);
    ctx.bezierCurveTo(coordsX + 8, coordsY + 17.5001, coordsX + 8.4, coordsY + 17.1001, coordsX + 9, coordsY + 17.1001);
    ctx.lineTo(coordsX + 19.5, coordsY + 17.1001);
    ctx.bezierCurveTo(coordsX + 20.1, coordsY + 17.1001, coordsX + 20.5, coordsY + 17.5001, coordsX + 20.5, coordsY + 18.1001);
    ctx.bezierCurveTo(coordsX + 20.5, coordsY + 18.7001, coordsX + 20.1, coordsY + 19.1001, coordsX + 19.5, coordsY + 19.1001);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 54.9, coordsY + 19.1001);
    ctx.lineTo(coordsX + 44.4, coordsY + 19.1001);
    ctx.bezierCurveTo(coordsX + 43.8, coordsY + 19.1001, coordsX + 43.4, coordsY + 18.7001, coordsX + 43.4, coordsY + 18.1001);
    ctx.bezierCurveTo(coordsX + 43.4, coordsY + 17.5001, coordsX + 43.8, coordsY + 17.1001, coordsX + 44.4, coordsY + 17.1001);
    ctx.lineTo(coordsX + 54.9, coordsY + 17.1001);
    ctx.bezierCurveTo(coordsX + 55.5, coordsY + 17.1001, coordsX + 55.9, coordsY + 17.5001, coordsX + 55.9, coordsY + 18.1001);
    ctx.bezierCurveTo(coordsX + 55.9, coordsY + 18.7001, coordsX + 55.5, coordsY + 19.1001, coordsX + 54.9, coordsY + 19.1001);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 37.5, coordsY + 19.1001);
    ctx.lineTo(coordsX + 26.4, coordsY + 19.1001);
    ctx.bezierCurveTo(coordsX + 25.8, coordsY + 19.1001, coordsX + 25.4, coordsY + 18.7001, coordsX + 25.4, coordsY + 18.1001);
    ctx.bezierCurveTo(coordsX + 25.4, coordsY + 17.5001, coordsX + 25.8, coordsY + 17.1001, coordsX + 26.4, coordsY + 17.1001);
    ctx.lineTo(coordsX + 37.5, coordsY + 17.1001);
    ctx.bezierCurveTo(coordsX + 38.1, coordsY + 17.1001, coordsX + 38.5, coordsY + 17.5001, coordsX + 38.5, coordsY + 18.1001);
    ctx.bezierCurveTo(coordsX + 38.5, coordsY + 18.7001, coordsX + 38.1, coordsY + 19.1001, coordsX + 37.5, coordsY + 19.1001);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 20.5, coordsY + 41.7002);
    ctx.lineTo(coordsX + 9.39999, coordsY + 41.7002);
    ctx.bezierCurveTo(coordsX + 8.79999, coordsY + 41.7002, coordsX + 8.39999, coordsY + 41.3002, coordsX + 8.39999, coordsY + 40.7002);
    ctx.bezierCurveTo(coordsX + 8.39999, coordsY + 40.1002, coordsX + 8.79999, coordsY + 39.7002, coordsX + 9.39999, coordsY + 39.7002);
    ctx.lineTo(coordsX + 20.5, coordsY + 39.7002);
    ctx.bezierCurveTo(coordsX + 21.1, coordsY + 39.7002, coordsX + 21.5, coordsY + 40.1002, coordsX + 21.5, coordsY + 40.7002);
    ctx.bezierCurveTo(coordsX + 21.5, coordsY + 41.3002, coordsX + 21.1, coordsY + 41.7002, coordsX + 20.5, coordsY + 41.7002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 54.5, coordsY + 41.7002);
    ctx.lineTo(coordsX + 43.4, coordsY + 41.7002);
    ctx.bezierCurveTo(coordsX + 42.8, coordsY + 41.7002, coordsX + 42.4, coordsY + 41.3002, coordsX + 42.4, coordsY + 40.7002);
    ctx.bezierCurveTo(coordsX + 42.4, coordsY + 40.1002, coordsX + 42.8, coordsY + 39.7002, coordsX + 43.4, coordsY + 39.7002);
    ctx.lineTo(coordsX + 54.5, coordsY + 39.7002);
    ctx.bezierCurveTo(coordsX + 55.1, coordsY + 39.7002, coordsX + 55.5, coordsY + 40.1002, coordsX + 55.5, coordsY + 40.7002);
    ctx.bezierCurveTo(coordsX + 55.5, coordsY + 41.3002, coordsX + 55.1, coordsY + 41.7002, coordsX + 54.5, coordsY + 41.7002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 37.5, coordsY + 41.7002);
    ctx.lineTo(coordsX + 26.4, coordsY + 41.7002);
    ctx.bezierCurveTo(coordsX + 25.8, coordsY + 41.7002, coordsX + 25.4, coordsY + 41.3002, coordsX + 25.4, coordsY + 40.7002);
    ctx.bezierCurveTo(coordsX + 25.4, coordsY + 40.1002, coordsX + 25.8, coordsY + 39.7002, coordsX + 26.4, coordsY + 39.7002);
    ctx.lineTo(coordsX + 37.5, coordsY + 39.7002);
    ctx.bezierCurveTo(coordsX + 38.1, coordsY + 39.7002, coordsX + 38.5, coordsY + 40.1002, coordsX + 38.5, coordsY + 40.7002);
    ctx.bezierCurveTo(coordsX + 38.5, coordsY + 41.3002, coordsX + 38.1, coordsY + 41.7002, coordsX + 37.5, coordsY + 41.7002);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 50.4, coordsY + 13.3999);
    ctx.lineTo(coordsX + 13.5, coordsY + 13.3999);
    ctx.bezierCurveTo(coordsX + 12.9, coordsY + 13.3999, coordsX + 12.5, coordsY + 12.9999, coordsX + 12.5, coordsY + 12.3999);
    ctx.bezierCurveTo(coordsX + 12.5, coordsY + 11.7999, coordsX + 12.9, coordsY + 11.3999, coordsX + 13.5, coordsY + 11.3999);
    ctx.lineTo(coordsX + 50.4, coordsY + 11.3999);
    ctx.bezierCurveTo(coordsX + 51, coordsY + 11.3999, coordsX + 51.4, coordsY + 11.7999, coordsX + 51.4, coordsY + 12.3999);
    ctx.bezierCurveTo(coordsX + 51.4, coordsY + 12.9999, coordsX + 50.9, coordsY + 13.3999, coordsX + 50.4, coordsY + 13.3999);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#2F66F2";
    ctx.font = "   15px ''";
    ctx.beginPath();
    ctx.moveTo(coordsX + 31.9, coordsY + 59.9);
    ctx.bezierCurveTo(coordsX + 16.5, coordsY + 59.9, coordsX + 4, coordsY + 47.3, coordsX + 4, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 4, coordsY + 16.5, coordsX + 16.5, coordsY + 4, coordsX + 31.9, coordsY + 4);
    ctx.bezierCurveTo(coordsX + 47.3, coordsY + 4,coordsX +  59.8, coordsY + 16.5, coordsX + 59.8, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 59.8, coordsY + 47.3, coordsX + 47.3, coordsY + 59.9, coordsX + 31.9, coordsY + 59.9);
    ctx.closePath();
    ctx.moveTo(coordsX + 31.9, coordsY + 6);
    ctx.bezierCurveTo(coordsX + 17.6, coordsY + 6, coordsX + 6, coordsY + 17.6, coordsX + 6, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 6, coordsY + 46.2, coordsX + 17.6, coordsY + 57.8, coordsX + 31.9, coordsY + 57.8);
    ctx.bezierCurveTo(coordsX + 46.2, coordsY + 57.8, coordsX + 57.8, coordsY + 46.2, coordsX + 57.8, coordsY + 31.9);
    ctx.bezierCurveTo(coordsX + 57.8, coordsY + 17.6, coordsX + 46.2, coordsY + 6, coordsX + 31.9, coordsY + 6);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
    ctx.restore();
  }
}

export default vectorDraws;
